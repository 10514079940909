import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { titleAnimation } from "../../animations/animations";
import dayDublin from "../../images/AboutUs/dayDublin.jpg";
import nightDublin from "../../images/AboutUs/NightDublin.jpg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";

export const AboutUs = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 1024;

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[1.875rem] min-h-[7.813rem] flex items-center mainPageLG:px-[15px]">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >
              {t("aboutUs.title")}
            </motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("aboutUs.title")}
            </div>
          )}
        </div>
      </section>
      <section className="py-[15px]">
        <div className="container-standard px-[1.875rem] text-[#ddb81e] text-xs font-bold mainPageLG:px-[15px]">
          <NavLink to="/">HOME</NavLink>
          <span> / </span>
          <span>{t("aboutUs.title")}</span>
        </div>
      </section>
      <section className="my-4 text-white md:my-[2.813rem]">
        <div className="container-standard px-[1.875rem] md:px-[7.5rem]">
          <div className="flex w-full flex-col mainPageLG:flex-row">
            <div className="w-full mainPageLG:w-[50%]">
              <p className="mb-10">{t("aboutUs.p1")}</p>
              <p>{t("aboutUs.p2")}</p>
            </div>
            <div className="w-full mt-10 mainPageLG:ml-[1.875rem] mainPageLG:w-[50%] mainPageLG:mt-0">
              <img src={dayDublin} alt="#" className="w-full h-full" />
            </div>
          </div>
          <div className="flex flex-col mt-[60px] mainPageLG:flex-row-reverse">
            <div className="w-full mainPageLG:w-[50%]">
              <p className="mb-[15px]">{t("aboutUs.p3")}</p>
              <p className="mb-[15px]">{t("aboutUs.p4")}</p>
              <p>{t("aboutUs.p5")}</p>
            </div>
            <div className= "w-full mt-10 mainPageLG:w-[50%] mainPageLG:mr-[1.875rem] mainPageLG:mt-0">
              <img src={nightDublin} alt="#" />
            </div>
          </div>
        </div>
      </section>
    </main>
  );
};
