import { configureStore, ThunkAction, Action } from "@reduxjs/toolkit";
import localeSlice from "./slices/localeSlice";
import ourTeamSlice from "./slices/ourTeamSlice";
import locationsSlice from "./slices/locationsSlice";
import routerSlice from "./slices/routerSlice";
import modalSlice from "./slices/modalSlice";
import phonesSlice from "./slices/phonesSlice";
import citiesSlice from "./slices/citiesSlice";
import logoFirstSlice from "./slices/logoFirstSlice";
import screenWidthSlice from "./slices/screenWidthSlice";

export const store = configureStore({
  reducer: {
    locale: localeSlice,
    ourTeam: ourTeamSlice,
    locations: locationsSlice,
    router: routerSlice,
    modal: modalSlice,
    phones: phonesSlice,
    cities: citiesSlice,
    logoFirst: logoFirstSlice,
    screenWidth: screenWidthSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
