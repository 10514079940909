import { useEffect, useRef, useState } from "react";
import { IAccordion } from "./AccordionInterface";
import { useAppSelector } from "../../store/hooks";
import Triangle from "../../images/GeneralImages/arrow.png";

export const Accordion = ({
  id,
  title,
  children,
  setIsOpen,
  isOpen,
}: IAccordion) => {
  const block = useRef<HTMLDivElement>(null);
  const content = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [isLoaded, setIsLoaded] = useState(false);
  const [defaultHeight, setDefaultHeight] = useState(0);
  const [contentHeight, setContentHeight] = useState(0);
  const [height, setHeight] = useState(0);
  const lang = useAppSelector((state) => state.locale);

  const handleExpand = (isOpen: string) => {
    let h = defaultHeight + contentHeight;
    if (isOpen !== id) {
      setIsExpanded(false);
      setHeight(defaultHeight);
    } else {
      setIsExpanded((prev) => !prev);
      setHeight((prev) => (prev = prev === defaultHeight ? h : defaultHeight));
    }
  };

  useEffect(() => {
    if (!isLoaded && block.current !== null && content.current !== null) {
      setIsLoaded(true);
      setIsExpanded(false);
      let summaryHeight =
        block.current.clientHeight - content.current.clientHeight;
      setDefaultHeight(summaryHeight);
      setHeight(summaryHeight);
      setContentHeight(content.current.clientHeight);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setIsLoaded(false);
  }, [lang.localStorageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isLoaded) {
      setIsOpen(" ");
      setIsExpanded(false);
      setHeight(defaultHeight);
      setIsLoaded(true);
    }
    if (!isLoaded && block.current !== null && content.current !== null) {
      let summaryHeight =
        block.current.clientHeight - content.current.clientHeight;
      setDefaultHeight(summaryHeight);
      setHeight(summaryHeight);
      setContentHeight(content.current.clientHeight);
      setHeight(defaultHeight);
    }
  }, [isLoaded]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isOpen !== "") {
      handleExpand(isOpen);
    }
  }, [isOpen]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <li className="mb-[15px]" id={id}>
      <div
        ref={block}
        style={isLoaded ? { height: `${height}px` } : {}}
        className={`expandedContainer relative rounded-md border border-gray-300 mb-[1.5rem]`}
      >
        <div
          className="relative select-none cursor-pointer font-semibold p-[1.25rem] flex justify-between"
          onClick={() => {
            if (isOpen === id) {
              setIsOpen(" ");
            } else {
              setIsOpen(id);
            }
          }}
        >
          <h3 className="text-[1rem] xxsm:text-[1.2rem]">{title}</h3>
          <div className="w-5">
            <button
              className={`toggle-expand-btn ${isExpanded ? `expanded` : ""}`}
            >
              <img src={Triangle} alt="marker" className="w-4" />
            </button>
          </div>
        </div>
        <div ref={content} className={`px-[1.25rem] pb-[1.25rem] special-font`}>
          {children}
        </div>
      </div>
    </li>
  );
};
