import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { menuType, subsectionType } from "../../types/menuTypes";
import { motion } from "framer-motion";
import { titleAnimation } from "../../animations/animations";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";

export const SiteMap = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const { t } = useTranslation();
  const titles = t("titles", { returnObjects: true }) as Array<menuType>;
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 1024;

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[30px] mainPageLG:px-[15px] min-h-[7.813rem] flex items-center">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >{t("siteMap.title")}</motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("siteMap.title")}
            </div>
          )}
        </div>
      </section>
      <section className="py-[15px]">
        <div className="container-standard px-[30px] mainPageLG:px-[15px] text-[#ddb81e] text-xs font-bold">
          <NavLink to="/">HOME</NavLink>
          <span> / </span>
          <span>{t("siteMap.title")}</span>
        </div>
      </section>
      <section className="px-[30px] upMD:px-[50px] mainPageLG:pl-[8%] container-standard">
        <div className="mt-[0.625rem] upMD:mt-[2rem] mb-[2rem]">
          <h2 className="mb-[1rem]">Pages</h2>
          {titles.map((title: menuType) => (
            <li
              className="group relative text-[#ddb81e] mt-2.5 mb-[15px]"
              key={title.title}
            >
              <NavLink
                to={`${title.route}`}
                className="text-[#ddb81e] text-lg hover:underline"
              >
                {title.title}
              </NavLink>
              {title.subTittles.length ? (
                <ul className="list-disc pl-[2.5rem]">
                  {title.subTittles.map((item: subsectionType) => (
                    <li
                      className="group relative mt-2.5 mb-[15px]"
                      key={item.subTittle}
                    >
                      <NavLink
                        to={`${item.route}`}
                        className="text-[#ddb81e] text-lg hover:underline"
                      >
                        {item.subTittle}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : null}
            </li>
          ))}
        </div>
      </section>
    </main>
  );
};
