import { useState } from "react";
import { ToggleMobMenu } from "../ToggleMobMenu/ToggleMobMenu";
import { IBurgerMenu } from "./BurgerMenuInterface";
import { useTranslation } from "react-i18next";
import { menuType } from "../../types/menuTypes";
import ukflag from "../../images/LanguagesImages/UKflag.svg";
import ruflag from "../../images/LanguagesImages/ruflag.png";
import gerflag from "../../images/LanguagesImages/gerflag.png";
import fraFlag from "../../images/LanguagesImages/fraflag.png"
import esflag from "../../images/LanguagesImages/spain.png";
import { useAppDispatch } from "../../store/hooks";
import { setLocale } from "../../store/slices/localeSlice";

export const BurgerMenu = ({ showMenu, closeNav }: IBurgerMenu) => {
  const [openIndex, setOpenIndex] = useState<null | number>(null);
  const { i18n, t } = useTranslation();
  const titles = t("titles", { returnObjects: true }) as Array<menuType>;
  const dispatch = useAppDispatch();

  const changeLanguage = (lng: string) => {
    dispatch(setLocale(lng));
    i18n.changeLanguage(lng);
  };

  const toggleMenu = (index: number | null) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index));
  };

  return (
    <div
      id="myNav"
      className={`bg-black mainPageLG:hidden fixed top-0 bot-0 right-0 pt-[3.25rem] overflow-y-scroll z-[10] transition-all duration-500 h-[100%] w-[100%] ${showMenu ? "left-0" : "-left-[103%]"
        }`}
    >
      <div className="relative w-full px-5 mt-20 text-base">
        {titles.map((title: menuType, index: number) => {
          return (
            <div key={title.title}>
              <ToggleMobMenu
                index={index}
                openIndex={openIndex}
                toggleMenu={toggleMenu}
                title={title}
                closeNav={closeNav}
              />
            </div>
          );
        })}
      </div>
      <p className="text-white text-lg mt-6 text-center font-semibold px-5">
        {t("burgerMenu.language")}
      </p>
      <div className="flex space-x-3 items-center justify-center mt-6 px-5 pb-4">
        <div>
          <img
            src={ukflag}
            alt="flag"
            className="w-[2rem] shadow-flagShadow"
            onClick={() => changeLanguage("en")}
          />
        </div>
        <div>
          <img
            src={ruflag}
            alt="flag"
            className="w-[2rem] shadow-flagShadow"
            onClick={() => changeLanguage("ru")}
          />
        </div>
        <div>
          <img
            src={gerflag}
            alt="flag"
            className="w-[2rem] shadow-flagShadow"
            onClick={() => changeLanguage("ge")}
          />
        </div>
        <div>
          <img
            src={fraFlag}
            alt="flag"
            className="w-[2rem] shadow-flagShadow"
            onClick={() => changeLanguage("fr")}
          />
        </div>
        <div>
          <img
            src={esflag}
            alt="flag"
            className="w-[2rem] shadow-flagShadow"
            onClick={() => changeLanguage("es")}
          />
        </div>
      </div>
    </div>
  );
};
