import { LocationInfo } from "../../types/SliceTypes/locationsState";
import { ILocation } from "./OneLocationInterface";
import Telephone from "../../images/LocationsImages/telephone.svg";
import Email from "../../images/LocationsImages/email.svg";

export const OneLocation = ({ locationGroup }: ILocation) => {
  return (
    <div className="px-5 pt-5 mb-5 shadow-locationGroupShadow bg-[#181a20] rounded">
      <h2 className="mb-5 text-[#c99400]">{locationGroup.groupName}</h2>
      <div className="flex flex-wrap">
        {locationGroup.items.map((branch: LocationInfo) => {
          return (
            <div className="xsm:w-1/2 mb-10 text-white" key={branch.name}>
              <h3 className="mb-[15px]">{branch.name}</h3>
              <div className="mb-2.5 max-w-[70%]">
                {branch.fullAddress}
                <span className="block">{branch.locationCode}</span>
              </div>
              <div className="leading-7 text-[#c99400] flex items-center">
                <div>
                  <img src={Telephone} alt="tel" className="w-3.5" />
                </div>
                <a href={`tel:${branch.phoneNumber}`} className="ml-2">{branch.phoneNumber}</a>
              </div>
              {branch.email ? (
                <div className="leading-7 text-[#c99400] flex items-center">
                  <div>
                    <img src={Email} alt="email" className="w-4" />
                  </div>
                  <a href={`mailto: ${branch.email}`} className="ml-2">{branch.email}</a>
                </div>
              ) : (
                ""
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};
