import Slider from "react-slick";
import { ICarousel } from "./CarouselInterface";
import { OurTeamInfo } from "../../types/SliceTypes/ourTeamState";
import { OurTeammate } from "../OurTeammate/OurTeammate";

const settings = {
  variableWidth: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 1500,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

export const Carousel = ({ teammates }: ICarousel) => {
  return (
    <div>
      <Slider {...settings}>
        {teammates.map((teammate: OurTeamInfo, index: number) => (
          <OurTeammate
            name={teammate.name}
            image={teammate.image}
            key={`${teammate.name} + ${index}`}
            styles="w-screen px-[1.875rem] xsm:px-[15px] xsm:w-[50vw] md:w-[385px]"
          />
        ))}
      </Slider>
    </div>
  );
};
