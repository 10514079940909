import { IUlColumned } from "./UlColumnedInterface";
import "./UlColumned.css";

export const UlColumned = ({
  title,
  items,
  columnsCount,
  styles,
}: IUlColumned) => {
  return (
    <div className={styles}>
      {title && <h2>{title}</h2>}

      <ul className="columned mt-[1.5rem]" style={{ columns: columnsCount }}>
        {items.map((text: string) => (
          <li key={text}>{text}</li>
        ))}
      </ul>
    </div>
  );
};
