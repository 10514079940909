import Slider from "react-slick";
import { OurTeamInfo } from "../../types/SliceTypes/ourTeamState";
import { OurTeammate } from "../OurTeammate/OurTeammate";
import { ICarousel } from "../Carousel/CarouselInterface";

let slides;

if (window.innerWidth <= 767) {
  slides = 1;
} else if (window.innerWidth <= 1279) {
  slides = 2;
} else if (window.innerWidth >= 1279) {
  slides = 3;
}

const settings = {
  infinite: true,
  slidesToShow: slides,
  speed: 500,
};

export const TeammatesSlider = ({ teammates }: ICarousel) => {
  return (
    <div>
      <Slider {...settings}>
        {teammates.map((teammate: OurTeamInfo, index: number) => (
          <OurTeammate
            name={teammate.name}
            image={teammate.image}
            key={`${teammate.name} + ${index}`}
            styles="w-[260px] bg-[#f9f9f9] !p-0 m-auto md:w-[300px]"
          />
        ))}
      </Slider>
    </div>
  );
};
