import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { MWhatWeDoImagesBox } from "../../components/WhatWeDoBox/WhatWeDoImagesBox";
import HouseImg from "../../images/WhatWeDoImages/houseImg.jpg";
import TrainingImg from "../../images/WhatWeDoImages/trainingImg.jpg";
import TrainingIcon from "../../images/WhatWeDoImages/WhatWeDoIcons/trainingIcon.png";
import AccountingImg from "../../images/WhatWeDoImages/accountingImg.jpg";
import AccountingIcon from "../../images/WhatWeDoImages/WhatWeDoIcons/accountingIcon.png";
import TaxImg from "../../images/WhatWeDoImages/texAndLegalImg.jpg";
import TaxIcon from "../../images/WhatWeDoImages/WhatWeDoIcons/taxIcon.png";
import FinansImg from "../../images/WhatWeDoImages/financialImg.jpg";
import FinansIcon from "../../images/WhatWeDoImages/WhatWeDoIcons/financialIcon.png";
import AgriImg from "../../images/WhatWeDoImages/agryImg.jpg";
import AgriIcon from "../../images/WhatWeDoImages/WhatWeDoIcons/agriIcon.png";
import ServicImg from "../../images/WhatWeDoImages/business.jpg";
import ServicIcon from "../../images/WhatWeDoImages/WhatWeDoIcons/businessIcon.png";
import AuditImg from "../../images/WhatWeDoImages/auditImg.jpg";
import AuditIcon from "../../images/WhatWeDoImages/WhatWeDoIcons/auditIcon.png";
import { motion } from "framer-motion";
import {
  arrOfBlocksAnimation,
  arrOfWhatWeDoRightAnimation,
  arrOfWhatWeDoUnderAnimation,
  bigWhatWeDoImgAnimation,
  titleAnimation,
} from "../../animations/animations";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";
import { useEffect } from "react";

export const WhatWeDo = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const iconUp = document.querySelectorAll(".backgroundTheme");
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 1024;
  iconUp.forEach((e) => {
    e.addEventListener("mouseover", () => {
      const parentElement = e.parentElement;
      if (e.parentElement !== undefined) {
        const x = parentElement?.getElementsByTagName("img")[1];
        if (x !== undefined) {
          x.style.transform = "translateY(-20px)";
          x.style.transition = "all 0.3s ease-out";
        }
      }
    });

    e.addEventListener("mouseout", () => {
      const parentElement = e.parentElement;
      if (e.parentElement !== undefined) {
        const x = parentElement?.getElementsByTagName("img")[1];
        if (x !== undefined) {
          x.style.transform = "translateY(0px)";
          x.style.transition = "all 0.3s ease-out";
        }
      }
    });
  });
  const { t } = useTranslation();

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[1.875rem] min-h-[7.813rem] flex items-center mainPageLG:px-[15px]">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >
              {t("what-we-do.wwd")}
            </motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("what-we-do.wwd")}
            </div>
          )}
        </div>
      </section>
      <section className="py-[15px]">
        <div className="container-standard px-[1.875rem] text-[#ddb81e] text-xs font-bold mainPageLG:px-[15px]">
          <NavLink to="/">HOME</NavLink>
          <span> / </span>
          <span>{t("what-we-do.wwd")}</span>
        </div>
      </section>
      <section className="bg-[#000000] hidden md:block">
        <div className="container-standard px-5 pt-[3.75rem] pb-[4.5rem] flex">
          <div className="w-1/2 px-[15px]">
            <motion.div
              initial="hidden"
              animate="visible"
              className="relative h-full max-h-[45.7vw] xl:max-h-[36.563rem] md:block"
            >
              <motion.span
                variants={bigWhatWeDoImgAnimation}
                className="absolute top-5 left-[1.875rem] z-10 w-[16.75rem] text-[0.8rem] text-white font-medium leading-[1.875rem] mainPageLG:right-[1.875rem] xl:text-[1.15rem] xl:w-[14rem]"
              >
                {t("what-we-do.longText")}
              </motion.span>
              <motion.img
                variants={bigWhatWeDoImgAnimation}
                src={HouseImg}
                alt="MainImg"
                className="brightness-50 h-full"
              />
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              className="flex justify-between mt-[1.875rem]"
            >
              <MWhatWeDoImagesBox
                custom={1}
                variants={arrOfWhatWeDoUnderAnimation}
                img={TrainingImg}
                firstRow={t("what-we-do.analytics")}
                icon={TrainingIcon}
                background={"rgb(221 184 30 / 57%)"}
                link="/analytics"
                styles="mr-[1.875rem]"
              />
              <MWhatWeDoImagesBox
                custom={2}
                variants={arrOfWhatWeDoUnderAnimation}
                img={AuditImg}
                firstRow={t("what-we-do.audit")}
                icon={AuditIcon}
                background={"rgb(0 0 0 / 75%)"}
                link="/audit"
              />
            </motion.div>
          </div>
          <div className="flex justify-between flex-col w-1/2 px-[15px]">
            <motion.div
              initial="hidden"
              animate="visible"
              className="flex h-[45.7vw] xl:h-[36.563rem]"
            >
              <div className="w-full flex flex-col items-start justify-between mr-[1.875rem]">
                <MWhatWeDoImagesBox
                  custom={1}
                  variants={arrOfWhatWeDoRightAnimation}
                  img={AccountingImg}
                  firstRow={t("what-we-do.accounting")}
                  icon={AccountingIcon}
                  background={"rgb(0 0 0 / 75%)"}
                  link="/accounting"
                />
                <MWhatWeDoImagesBox
                  custom={1}
                  variants={arrOfWhatWeDoRightAnimation}
                  img={TaxImg}
                  firstRow={t("what-we-do.taxLegal")}
                  icon={TaxIcon}
                  background={"rgb(221 184 30 / 57%)"}
                  link="/tax-planning"
                />
              </div>
              <div className="w-full flex flex-col items-end justify-between">
                <MWhatWeDoImagesBox
                  custom={2}
                  variants={arrOfWhatWeDoRightAnimation}
                  img={FinansImg}
                  firstRow={t("what-we-do.financial")}
                  icon={FinansIcon}
                  background={"rgb(0 0 0 / 75%)"}
                  link="/financial-services"
                />
                <MWhatWeDoImagesBox
                  custom={2}
                  variants={arrOfWhatWeDoRightAnimation}
                  img={AgriImg}
                  firstRow={t("what-we-do.legal")}
                  icon={AgriIcon}
                  background={"rgb(221 184 30 / 57%)"}
                  link="/legal-aid"
                />
              </div>
            </motion.div>
            <motion.div
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.3, once: true }}
              className="flex justify-between mt-[1.875rem] [&>*]:max-w-full [&>*]:w-full"
            >
              <MWhatWeDoImagesBox
                custom={4}
                variants={arrOfWhatWeDoUnderAnimation}
                img={ServicImg}
                firstRow={t("what-we-do.services")}
                icon={ServicIcon}
                background={"rgb(0 0 0 / 75%)"}
                link="/business-services"
              />
            </motion.div>
          </div>
        </div>
      </section>

      <section className="block px-[1.875rem] py-4 md:py-[4.375rem] md:hidden mainPageLG:px-[15px]">
        <motion.div
          initial="hidden"
          whileInView="visible"
          viewport={{ amount: 0.3, once: true }}
          className="h-500px [&>*]:mb-2.5"
        >
          <MWhatWeDoImagesBox
            custom={1}
            variants={arrOfBlocksAnimation}
            firstRow={t("what-we-do.analytics")}
            icon={TrainingIcon}
            background={"rgb(14 15 17)"}
            link="/analytics"
          />
          <MWhatWeDoImagesBox
            custom={2}
            variants={arrOfBlocksAnimation}
            firstRow={t("what-we-do.services")}
            icon={ServicIcon}
            background={"rgb(14 15 17)"}
            link="/business-services"
          />
          <MWhatWeDoImagesBox
            custom={3}
            variants={arrOfBlocksAnimation}
            firstRow={t("what-we-do.accounting")}
            icon={AccountingIcon}
            background={"rgb(14 15 17)"}
            link="/accounting"
          />
          <MWhatWeDoImagesBox
            custom={4}
            variants={arrOfBlocksAnimation}
            firstRow={t("what-we-do.financial")}
            icon={FinansIcon}
            background={"rgb(14 15 17)"}
            link="/financial-services"
          />
          <MWhatWeDoImagesBox
            custom={5}
            variants={arrOfBlocksAnimation}
            firstRow={t("what-we-do.taxLegal")}
            icon={TaxIcon}
            background={"rgb(14 15 17)"}
            link="/tax-planning"
          />
          <MWhatWeDoImagesBox
            custom={6}
            variants={arrOfBlocksAnimation}
            firstRow={t("what-we-do.legal")}
            icon={AgriIcon}
            background={"rgb(14 15 17)"}
            link="/legal-aid"
          />
          <MWhatWeDoImagesBox
            custom={7}
            variants={arrOfBlocksAnimation}
            firstRow={t("what-we-do.audit")}
            icon={AuditIcon}
            background={"rgb(14 15 17)"}
            link="/business-services"
          />
        </motion.div>
      </section>
    </main>
  );
};
