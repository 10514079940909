import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { OtherServicesBlock } from "../../components/OtherServicesBlock/OtherServicesBlock";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { OurTeamInfo } from "../../types/SliceTypes/ourTeamState";
import { OurTeammate } from "../../components/OurTeammate/OurTeammate";
import { fetchOurTeam } from "../../store/slices/ourTeamSlice";
import { TeammatesSlider } from "../../components/TeammatesSlider/TeammatesSlider";
import { Loader } from "../../components/Loader/Loader";
import { Accordion } from "../../components/Accordion/Accordion";
import { accordionType, paragraphsType } from "../../types/accordionTypes";
import { UlColumned } from "../../components/UlColumned/UlColumned";
import { motion } from "framer-motion";
import { titleAnimation } from "../../animations/animations";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";

export const FinancialServices = () => {
  const { t } = useTranslation();
  const { teammates } = useAppSelector((state) => state.ourTeam);
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const [someTeammates, setSomeTeammates] = useState<OurTeamInfo[]>();
  const [isOpen, setIsOpen] = useState<string>("");
  const lang = useAppSelector((state) => state.locale);
  const dispatch = useAppDispatch();
  const accordionInfo = t("financialServices.accordion", {
    returnObjects: true,
  }) as Array<accordionType>;
  const targetIds = [15, 13];
  const isDesktop = screenWidth > 1024;

  useEffect(() => {
    if (!teammates.length) {
      dispatch(fetchOurTeam({ language: lang.localStorageValue }));
    }
    setSomeTeammates(teammates.filter((obj) => targetIds.includes(obj.id)));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchOurTeam({ language: lang.localStorageValue }));
  }, [lang.localStorageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setSomeTeammates(teammates.filter((obj) => targetIds.includes(obj.id)));
  }, [teammates.length, teammates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[1.875rem] min-h-[7.813rem] flex items-center mainPageLG:px-[15px]">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >
              {t("financialServices.title")}
            </motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("financialServices.title")}
            </div>
          )}
        </div>
      </section>
      <section className="py-[15px]">
        <div className="container-standard px-[1.875rem] text-[#ddb81e] text-xs font-bold mainPageLG:px-[15px]">
          <NavLink to="/">{t("financialServices.mainPage")}</NavLink>
          <span> / </span>
          <NavLink to="/what-we-do">
            {t("financialServices.whatWeDoPage")}
          </NavLink>
          <span> / </span>
          <span>{t("financialServices.title")}</span>
        </div>
      </section>
      <section className="my-4 md:my-[2.813rem]">
        <div className="container-standard px-[1.875rem] flex flex-col justify-between md:px-[2.5rem] xl:px-[7.5rem] md:flex-row">
          <div className="w-full mr-[1.875rem] text-white md:w-whatWeDoCalcAdaptive whatWeDoPage:w-whatWeDoCalc whatWeDoPage:mr-0">
            <h1>{t("financialServices.textMainTitle")}</h1>
            <p className="mt-2.5">{t("financialServices.p1")}</p>
            <p className="mt-[1.563rem]">{t("financialServices.p2")}</p>
            <p className="mt-[1.563rem]">{t("financialServices.p3")}</p>
            <p className="mt-[1.563rem]">{t("financialServices.p4")}</p>
            <h2 className="mt-[1.563rem]">{t("financialServices.secondH1")}</h2>
            <UlColumned
              styles="my-2.5"
              columnsCount={1}
              items={[
                t("financialServices.li1"),
                t("financialServices.li2"),
                t("financialServices.li3"),
                t("financialServices.li4"),
                t("financialServices.li5"),
              ]}
            />
            <h2>{t("financialServices.secondH4")}</h2>
            <p className="mt-[1.563rem]">{t("financialServices.p5")}</p>
            <p className="mt-[1.563rem]">{t("financialServices.p6")}</p>
            <p className="mt-[1.563rem]">{t("financialServices.p7")}</p>
            <p className="mt-[1.563rem]">{t("financialServices.p8")}</p>
            <h2 className="mt-[1.563rem]">{t("financialServices.secondH2")}</h2>
            <div className="pt-[1.875rem]">
              <ul>
                {accordionInfo.map(
                  (oneAccordion: accordionType, index: number) => {
                    return (
                      <Accordion
                        id={index.toString()}
                        title={oneAccordion.accordionInfo.title}
                        setIsOpen={setIsOpen}
                        isOpen={isOpen}
                        key={index}
                      >
                        {oneAccordion.accordionInfo.paragraphs.map(
                          (paragraph: paragraphsType) => {
                            return (
                              <p className="pt-4" key={paragraph.paragraph}>
                                {paragraph.paragraph}
                              </p>
                            );
                          }
                        )}
                      </Accordion>
                    );
                  }
                )}
              </ul>
            </div>
            <h2 className="mt-[1.563rem]">{t("financialServices.secondH3")}</h2>
            <p className="mt-[1.563rem]">{t("financialServices.p9")}</p>
            <p className="mt-[1.563rem] text-sm">
              {t("financialServices.p10")}
            </p>
          </div>
          <div className="w-full mt-[2rem] md:w-[34%] whatWeDoPage:w-[28%] md:mt-0">
            {someTeammates?.map((teammate: OurTeamInfo) => {
              return (
                <OurTeammate
                  name={teammate.name}
                  image={teammate.image}
                  styles="bg-white mb-[1.875rem] w-full mx-auto !p-0 md:mx-0"
                  key={teammate.id}
                />
              );
            })}
            <OtherServicesBlock />
          </div>
        </div>
      </section>
      <section className="py-[3.125rem] border-t border-t-[#757575]">
        <div className="container-standard px-[1.875rem] xxxsm:px-[3rem] md:px-[5.5rem] slidePadding:px-[7.5rem]">
          <h2 className="mb-[1.875rem]">{t("accounting.relatedExperts")}</h2>
          {teammates.length ? (
            <TeammatesSlider teammates={teammates} />
          ) : (
            <Loader height="120" width="1230" />
          )}
        </div>
      </section>
    </main>
  );
};
