import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import WhatWeDo2 from "../../images/MainImages/agri_consultancy.png";
import WhatWeDo3 from "../../images/MainImages/audit.png";
import WhatWeDo4 from "../../images/MainImages/business_services.png";
import WhatWeDo5 from "../../images/MainImages/calculator.png";
import WhatWeDo6 from "../../images/MainImages/financial_services.png";
import WhatWeDo7 from "../../images/MainImages/tax.png";
import WhatWeDo8 from "../../images/MainImages/training.png";
import signature from "../../images/MainImages/hp-welcome-signature.svg";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { fetchOurTeam } from "../../store/slices/ourTeamSlice";
import { fetchCities } from "../../store/slices/citiesSlice";
import { Carousel } from "../../components/Carousel/Carousel";
import { NavLink } from "react-router-dom";
import { Loader } from "../../components/Loader/Loader";
import { motion } from "framer-motion";
import { MWhatWeDoBox } from "../../components/WhatWeDoBox/WhatWeDoBox";
import { arrOfBlocksAnimation } from "../../animations/animations";
import { ActivateModal } from "../../components/ActivateModal/ActivateModal";
import { MainCarousel } from "../../components/Carousel/MainCarousel";
import MainLeader from "../../images/MainImages/head-leader.png";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";

export const MainPage = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const teammates = useAppSelector((state) => state.ourTeam.teammates);
  const lang = useAppSelector((state) => state.locale);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 1024;

  useEffect(() => {
    dispatch(fetchOurTeam({ language: lang.localStorageValue }));
    dispatch(fetchCities({ language: lang.localStorageValue }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchOurTeam({ language: lang.localStorageValue }));
    dispatch(fetchCities({ language: lang.localStorageValue }));
  }, [lang.localStorageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="overflow-hidden">
        <MainCarousel />
      </section>
      <section
        className={`border-y-2 border-y-[#fcd53533] ${
          isDesktop ? "mainLeader" : ""
        } `}
      >
        <div className="container-standard px-[0.7rem] flex flex-col items-center sm:px-[1.875rem] mainPageLG:px-[15px] mainPageLG:items-start">
          <div className="pt-10 max-w-[95%] text-lg leading-6 text-white mainPageLG:max-w-[40.625rem] mainPageLG:pt-[6.25rem]">
            <div className="mb-2.5">{t("mainPage.leader.subtitle")}</div>
            <h2 className="text-white mb-5 text-[2.225rem] leading-[2.75rem] font-bold">
              {t("mainPage.leader.title")}
            </h2>
            <p className="mb-[15px] font-semibold">{t("mainPage.leader.p1")}</p>
            <p className="mb-[15px]">{t("mainPage.leader.p2")}</p>
            <p className="mb-[15px]">{t("mainPage.leader.p3")}</p>
            <div className="flex flex-row items-center">
              <span className="font-bold text-2xl pr-[3.125rem] text-center">
                {t("mainPage.leader.name")}
              </span>
              {/* <img
                src={signature}
                alt="signature"
                className="w-[60%] sm:w-[40%] mainPageLG:w-[50%]"
              /> */}
            </div>
            <div className=" mt-8">
              <NavLink
                to="/about-us/"
                className={`bg-[#fef6d8] font-medium rounded-[3.125rem] text-[#c99400] px-9 py-3 hover:bg-white hover:text-black duration-500 `}
              >
                {t("mainPage.readMore")}
              </NavLink>
            </div>
          </div>
          {isDesktop ? null : (
            <div className="mt-10">
              <img
                src={MainLeader}
                alt="Leader"
                className="m-auto w-full md:w-[70%]"
              />
            </div>
          )}
        </div>
      </section>

      <section className="bg-black py-[3.125rem] border-b border-b-[#fcd53533]">
        <div className="container-standard">
          <h2 className="text-center text-[1.625rem] mb-[3.125rem] text-white">
            {t("mainPage.whatWeDoSection.whatWeDo")}
          </h2>
          <div className="max-w-[60rem] px-[15px] m-auto">
            <motion.ul
              initial="hidden"
              whileInView="visible"
              viewport={{ amount: 0.1, once: true }}
              className="flex flex-wrap"
            >
              <MWhatWeDoBox
                custom={1}
                variants={arrOfBlocksAnimation}
                info={t("mainPage.whatWeDoSection.whatWeDoExperts")}
                infoStyles="text-2xl font-bold p-[1.875rem] leading-[1.875rem] text-left"
              />
              <MWhatWeDoBox
                custom={2}
                variants={arrOfBlocksAnimation}
                info={t("mainPage.whatWeDoSection.accounting")}
                img={WhatWeDo5}
                link="what-we-do/accounting"
              />
              <MWhatWeDoBox
                custom={3}
                variants={arrOfBlocksAnimation}
                info={t("mainPage.whatWeDoSection.tax")}
                img={WhatWeDo7}
                link="what-we-do/tax-planning"
              />
              <MWhatWeDoBox
                custom={4}
                variants={arrOfBlocksAnimation}
                info={t("mainPage.whatWeDoSection.financial")}
                img={WhatWeDo6}
                link="what-we-do/financial-services"
              />
              <MWhatWeDoBox
                custom={5}
                variants={arrOfBlocksAnimation}
                info={t("mainPage.whatWeDoSection.legalAid")}
                img={WhatWeDo2}
                link="what-we-do/legal-aid"
              />
              <MWhatWeDoBox
                custom={6}
                variants={arrOfBlocksAnimation}
                info={t("mainPage.whatWeDoSection.audit")}
                img={WhatWeDo3}
                link="what-we-do/audit"
              />
              <MWhatWeDoBox
                custom={7}
                variants={arrOfBlocksAnimation}
                info={t("mainPage.whatWeDoSection.business")}
                img={WhatWeDo4}
                link="what-we-do/business-services"
              />
              <MWhatWeDoBox
                custom={8}
                variants={arrOfBlocksAnimation}
                info={t("mainPage.whatWeDoSection.analytics")}
                img={WhatWeDo8}
                link="what-we-do/analytics"
              />
            </motion.ul>
          </div>
        </div>
      </section>

      <section className="bg-[#0e0f11] pt-10 pb-[1.875rem]">
        <h2 className="mb-[1.563rem] text-center">
          {t("mainPage.yourTeam.meetYourTeam")}
        </h2>
        <div className="mb-8 mainPageLG:mb-[3.125rem]">
          {teammates.length ? (
            <Carousel teammates={teammates} />
          ) : (
            <Loader height="120" width="1230" />
          )}
        </div>
        <div className="container-standard text-center">
          <NavLink
            to="our-team"
            className="text-[0.9rem] py-3 px-9 text-[#c99400] bg-[#fef6d8] font-medium rounded-[3.125rem] hover:bg-white hover:text-black duration-500"
          >
            {t("mainPage.yourTeam.meetYourTeam")}
          </NavLink>
        </div>
      </section>
      <ActivateModal />
    </main>
  );
};
