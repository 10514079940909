import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { OtherServicesBlock } from "../../components/OtherServicesBlock/OtherServicesBlock";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect, useState } from "react";
import { OurTeamInfo } from "../../types/SliceTypes/ourTeamState";
import { OurTeammate } from "../../components/OurTeammate/OurTeammate";
import { fetchOurTeam } from "../../store/slices/ourTeamSlice";
import { UlColumned } from "../../components/UlColumned/UlColumned";
import { TeammatesSlider } from "../../components/TeammatesSlider/TeammatesSlider";
import { Loader } from "../../components/Loader/Loader";
import { motion } from "framer-motion";
import { titleAnimation } from "../../animations/animations";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";

export const Accounting = () => {
  const { t } = useTranslation();
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const { teammates } = useAppSelector((state) => state.ourTeam);
  const [oneTeammate, setOneTeammate] = useState<OurTeamInfo>();
  const lang = useAppSelector((state) => state.locale);
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 1024;

  useEffect(() => {
    if (!teammates.length) {
      dispatch(fetchOurTeam({ language: lang.localStorageValue }));
    }
    setOneTeammate(
      teammates.find((teammate: OurTeamInfo) => teammate.id === 11)
    );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchOurTeam({ language: lang.localStorageValue }));
  }, [lang.localStorageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setOneTeammate(
      teammates.find((teammate: OurTeamInfo) => teammate.id === 11)
    );
  }, [teammates.length, teammates]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[1.875rem] min-h-[7.813rem] flex items-center mainPageLG:px-[15px]">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >
              {t("accounting.title")}
            </motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("accounting.title")}
            </div>
          )}
        </div>
      </section>
      <section className="py-[15px]">
        <div className="container-standard px-[1.875rem] text-[#ddb81e] text-xs font-bold mainPageLG:px-[15px]">
          <NavLink to="/">{t("accounting.mainPage")}</NavLink>
          <span> / </span>
          <NavLink to="/what-we-do">{t("accounting.whatWeDoPage")}</NavLink>
          <span> / </span>
          <span>{t("accounting.title")}</span>
        </div>
      </section>
      <section className="my-4 md:my-[2.813rem]">
        <div className="container-standard px-[1.875rem] flex flex-col justify-between md:px-[2.5rem] xl:px-[7.5rem] md:flex-row">
          <div className="w-full mr-[1.875rem] text-white md:w-whatWeDoCalcAdaptive whatWeDoPage:w-whatWeDoCalc whatWeDoPage:mr-0">
            <h1>{t("accounting.textTitle")}</h1>
            <p className="mt-2.5">{t("accounting.p1")}</p>
            <p className="mt-[1.563rem]">{t("accounting.p2")}</p>
            <p className="mt-[1.563rem]">{t("accounting.p3")}</p>
            <p className="mt-[1.563rem]">{t("accounting.p4")}</p>
            <UlColumned
              styles="my-2.5 hidden md:block"
              columnsCount={2}
              items={[
                t("accounting.li1"),
                t("accounting.li2"),
                t("accounting.li3"),
                t("accounting.li4"),
                t("accounting.li5"),
                t("accounting.li6"),
                t("accounting.li7"),
                t("accounting.li8"),
                t("accounting.li9"),
                t("accounting.li10"),
                t("accounting.li11"),
              ]}
            />
            <UlColumned
              styles="my-2.5 block md:hidden"
              columnsCount={1}
              items={[
                t("accounting.li1"),
                t("accounting.li2"),
                t("accounting.li3"),
                t("accounting.li4"),
                t("accounting.li5"),
                t("accounting.li6"),
                t("accounting.li7"),
                t("accounting.li8"),
                t("accounting.li9"),
                t("accounting.li10"),
                t("accounting.li11"),
              ]}
            />
            <p className="mt-[1.563rem]">{t("accounting.p5")}</p>
          </div>
          <div className="w-full mt-[2rem] md:w-[34%] whatWeDoPage:w-[28%] md:mt-0">
            {oneTeammate && (
              <OurTeammate
                name={oneTeammate.name}
                image={oneTeammate.image}
                styles="bg-white mb-[1.875rem] w-full mx-auto !p-0 md:mx-0"
              />
            )}
            <OtherServicesBlock />
          </div>
        </div>
      </section>
      <section className="py-[3.125rem] border-t border-t-[#757575]">
        <div className="container-standard px-[1.875rem] xxxsm:px-[3rem] md:px-[5.5rem] slidePadding:px-[7.5rem]">
          <h2 className="mb-[1.875rem]">{t("accounting.relatedExperts")}</h2>
          {teammates.length ? (
            <TeammatesSlider teammates={teammates} />
          ) : (
            <Loader height="120" width="1230" />
          )}
        </div>
      </section>
    </main>
  );
};
