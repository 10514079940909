import { useTranslation } from "react-i18next";
import LogoWhite from "../../images/GeneralImages/LogoWhite.svg";
import { NavLink } from "react-router-dom";
import newCompany from "../../images/FooterImages/aib-logo-1.png"
import Petro from "../../images/FooterImages/petroceltic.png"
import Three from "../../images/FooterImages/Three-Logo-White.png"
import IrishBank from "../../images/FooterImages/irishBank.png"

export const Footer = () => {
  const { t } = useTranslation();

  return (
    <footer className="bg-black py-[1.87rem] text-white">
      <div className="px-[1.87rem] container-standard">
        <div className="py-[15px]">
          <div className="container-sm">
            <img src={LogoWhite} alt="Emblem" className="m-auto max-w-[220px] w-[40%] mainPageLG:w-[20%]" />
          </div>
        </div>
        <div className="items-center mainPageLG:items-start py-[15px] flex flex-col mainPageLG:flex-row mainPageLG:justify-between">
          <div className="items-center mainPageLG:items-start max-w-[600px] mainPageLG:w-2/4">
            <ul className="grid xsm:flex justify-center mainPageLG:justify-start">
              <li className="pl-[12px] xsm:pl-[0px] text-sm leading-snug">
                <NavLink to="/site-map" className="hover:underline">
                  {t("footer.sitemap")}
                </NavLink>
              </li>
              <li className="text-sm leading-snug footer-li">
                <NavLink to="/privacy" className="hover:underline">
                  {t("footer.privacyPolicy")}
                </NavLink>
              </li>
              <li className="text-sm leading-snug footer-li">
                <NavLink to="/about-us" className="hover:underline">
                  {t("footer.aboutUs")}
                </NavLink>
              </li>
            </ul>
            <div className="my-[3.75rem] text-center mainPageLG:text-start">
              <p className="text-sm">
                {t("footer.footerText")}
              </p>
            </div>
            <div></div>
          </div>
          <div className="flex flex-col items-center mainPageLG:items-end text-sm mainPageLG:w-2/4">
            <p>© PACEM</p>
            <div className="my-[1.875rem] flex flex-col items-center mainPageLG:items-end">
              <p className="mb-5"> {t("footer.proudlySupport")}</p>
              <ul className="flex space-x-8">
                <li>
                  <img src={newCompany} alt="Company" className="h-[2.4rem] sm:h-[3rem]" />
                </li>
                <li>
                  <img src={Petro} alt="Company" className="h-[2.4rem] sm:h-[3rem]" />
                </li>
                <li>
                  <img src={Three} alt="Company" className="h-[2.4rem] sm:h-[3rem]" />
                </li>
                <li>
                  <img src={IrishBank} alt="Company" className="h-[2.4rem] sm:h-[3rem]" />
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
