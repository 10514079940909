import { motion } from "framer-motion";
import React from "react";

export const ActiveLine = () => {
  return (
    <motion.div
      layoutId="activeItem"
      className="w-activeLineCalc h-1 absolute bottom-[-6px] left-[5px] bg-[#ddb81e]"
    />
  );
};
