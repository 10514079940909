import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { fetchOurTeam } from "../../store/slices/ourTeamSlice";
import { setOurTeammates } from "../../functions/SetParts";
import { Loader } from "../../components/Loader/Loader";
import { motion } from "framer-motion";
import { titleAnimation } from "../../animations/animations";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";

export const OurTeam = () => {
  const { teammates } = useAppSelector((state) => state.ourTeam);
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const lang = useAppSelector((state) => state.locale);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 1024;

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  useEffect(() => {
    dispatch(fetchOurTeam({ language: lang.localStorageValue }));
  }, [lang.localStorageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[1.875rem] min-h-[7.813rem] flex items-center mainPageLG:px-[15px]">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >
              {t("ourTeam.ourTeamTitle")}
            </motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("ourTeam.ourTeamTitle")}
            </div>
          )}
        </div>
      </section>
      <section className="py-[15px]">
        <div className="container-standard px-[1.875rem] text-[#ddb81e] text-xs font-bold mainPageLG:px-[15px]">
          <NavLink to="/">HOME</NavLink>
          <span> / </span>
          <span>{t("ourTeam.ourTeamTitle")}</span>
        </div>
      </section>
      <section className="bg-black mt-[0.625rem] upMD:mt-[2.813rem]">
        <div className="font-medium px-[1.875rem] container-standard max-w-[75rem] min-h-[7.813rem] space-y-5 pb-[1.875rem] text-white text-base upMD:px-[50px] mainPageLG:px-[15px]">
          <p>{t("topManagement.p1")}</p>
          <p>{t("topManagement.p2")}</p>
          <p>{t("topManagement.p3")}</p>
          <p>{t("topManagement.p4")}</p>
        </div>
      </section>
      <section className="container-standard">
        {teammates.length ? (
          <div className="flex flex-wrap justify-center mainPageLG:justify-between ">
            {setOurTeammates(teammates)}
          </div>
        ) : (
          <Loader height="300" width="1230" styles="py-10" />
        )}
      </section>
    </main>
  );
};
