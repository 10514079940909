import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import EnTranslation from "./locales/EN.json";
import RuTranslation from "./locales/RU.json";
import EsTranslation from "./locales/ES.json";
import GeTranslation from "./locales/GE.json"
import FrTranslation from "./locales/FR.json"

const local = localStorage.getItem("locale") || "en";

i18n.use(initReactI18next).init({
  resources: {
    en: { translation: EnTranslation },
    ru: { translation: RuTranslation },
    es: { translation: EsTranslation },
    ge: { translation: GeTranslation },
    fr: { translation: FrTranslation }
  },
  lng: local,
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
