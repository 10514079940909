import { NavLink } from "react-router-dom";
import { ImgBoxInterface } from "../../Interfaces/ImagesBoxInterface";
import { ForwardedRef, forwardRef } from "react";
import { motion } from "framer-motion";

export const CareersBox = forwardRef(
  (
    {
      img,
      icon,
      firstRow,
      background,
      width,
      height,
      link,
    }: ImgBoxInterface,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div ref={ref} className="my-[5px] h-[15.625rem] relative overflow-hidden upMD:w-[32%]">
        <NavLink target="_blank" to={link}>
          {img && (
            <div
              className={`absolute w-full h-full top-0 left-0 hover:scale-125 transition duration-300 ease-out cursor-pointer bg-no-repeat bg-cover bg-center`}
              style={{ backgroundImage: `url(${img})` }}
            ></div>
          )}

          {background && (
            <div
              style={{ background: `${background}` }}
              className="w-full h-full absolute top-0 left-0 pointer-events-none"
            ></div>
          )}
          <div className="w-[calc(100%-2.5rem)] pointer-events-none h-full absolute bottom-5 left-5 flex flex-col justify-end items-center">
            {icon && (
              <img
                style={{ width: `${width}`, height: `${height}` }}
                className={`mb-5`}
                src={icon}
                alt={`WhatWeDo`}
              ></img>
            )}

            {firstRow && (
              <p className="text-white text-center text-3xl uppercase font-medium pb-5">
                {firstRow}
              </p>
            )}
          </div>
        </NavLink>
      </div>
    );
  }
);

export const MCareersBox = motion(CareersBox);
