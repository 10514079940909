import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { routerState } from "../../types/SliceTypes/routerState";

const initialState: routerState = {
  currentRoute: "/",
  secRoute: "/"
};

const routerSlice = createSlice({
  name: "router",

  initialState,

  reducers: {
    setCurrentRoute: (state, action: PayloadAction<string>) => {
      state.currentRoute = action.payload;
    },
    setSecRoute: (state, action: PayloadAction<string>) => {
      state.secRoute = action.payload;
    },
  },
});

export const { setCurrentRoute, setSecRoute } = routerSlice.actions;
export default routerSlice.reducer;
