import { createSlice } from "@reduxjs/toolkit";
import { logoFirstState } from "../../types/SliceTypes/logoFirstState";

const initialState: logoFirstState = {
  isFirstLoad: true,
};

const logoFirstSlice = createSlice({
  name: "logoFirst",

  initialState,

  reducers: {
    setFirstLoad: (state) => {
      state.isFirstLoad = false;
    },
  },
});

export const { setFirstLoad } = logoFirstSlice.actions;
export default logoFirstSlice.reducer;
