import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { MCareersBox } from "../../components/CareersBox/CareersBox";
import WomanImg from "../../images/CareersImages/woman.jpg";
import Team from "../../images/CareersImages/team.jpg";
import GeometryImg from "../../images/CareersImages/geometry.jpg";
import Unicef from "../../images/CareersImages/CareersIcons/icon-graduate.png";
import VacantIcon from "../../images/CareersImages/CareersIcons/icon-vacant.png";
import PotentialIcon from "../../images/CareersImages/CareersIcons/potential.png";
import { motion } from "framer-motion";
import {
  arrOfCareerAnimation,
  titleAnimation,
} from "../../animations/animations";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";

export const Charity = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const { t } = useTranslation();
  const isDesktop = screenWidth > 1024;
  const dispatch = useAppDispatch();

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[1.875rem] min-h-[7.813rem] flex items-center mainPageLG:px-[15px]">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >
              {t("charity.title")}
            </motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("charity.title")}
            </div>
          )}
        </div>
      </section>
      <section className="bg-[#000000] py-[15px]">
        <div className="container-standard px-[1.875rem] text-[#ddb81e] text-xs font-bold mainPageLG:px-[15px]">
          <NavLink to="/">HOME</NavLink>
          <span> / </span>
          <span>{t("charity.title")}</span>
        </div>
      </section>
      <section className="bg-black mt-[0.625rem] upMD:mt-[2.813rem]">
        <div className="font-medium px-[1.875rem] container-standard max-w-[75rem] min-h-[7.813rem] space-y-5 pb-[1.875rem] text-white text-base upMD:px-[50px] mainPageLG:px-[15px]">
          <p>{t("charity.p1")}</p>
          <p>{t("charity.p2")}</p>
          <p>{t("charity.p3")}</p>
          <p>{t("charity.p4")}</p>
          <p>{t("charity.p5")}</p>
        </div>
      </section>
      <section className="bg-black">
        <motion.div
          initial="hidden"
          animate="visible"
          className="container-standard px-[1.875rem] pb-10 flex flex-col flex-wrap upMD:justify-between upMD:px-[50px] upMD:flex-row mainPageLG:px-[15px]"
        >
          <MCareersBox
            custom={1}
            variants={arrOfCareerAnimation}
            img={Team}
            firstRow={t("charity.blockInfo.unicef")}
            icon={Unicef}
            background={"rgb(0 0 0 / 75%)"}
            width={"6rem"}
            height={"5rem"}
            link="https://www.unicef.org/"
          />
          <MCareersBox
            custom={2}
            variants={arrOfCareerAnimation}
            img={WomanImg}
            firstRow={t("charity.blockInfo.trust")}
            icon={VacantIcon}
            background={"rgb(221 184 30 / 57%)"}
            width={"6rem"}
            height={"5rem"}
            link="https://wellcome.org/"
          />
          <MCareersBox
            custom={3}
            variants={arrOfCareerAnimation}
            img={GeometryImg}
            firstRow={t("charity.blockInfo.kickstarter")}
            icon={PotentialIcon}
            background={"rgb(0 0 0 / 75%)"}
            width={"6rem"}
            height={"5rem"}
            link="https://www.kickstarter.com/"
          />
        </motion.div>
      </section>
    </main>
  );
};
