import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  FetchInfoError,
  GenericFetchType,
  LangRequest,
  ResponseType,
} from "../../types/SliceTypes/sliceType";
import axios from "axios";
import { MAINLINK } from "../../constants/API";
import { PhoneInfo, PhonesState } from "../../types/SliceTypes/phonesState";

export const fetchPhones = createAsyncThunk<
  ResponseType,
  LangRequest,
  { rejectValue: FetchInfoError }
>("phones/fetchPhones", async (request: LangRequest, thunkApi) => {
  const response = await axios.get(
    `${MAINLINK}/locations/phones/${request.language}`
  );

  if (response.status !== 200) {
    return thunkApi.rejectWithValue({
      message: "Failed to fetch info.",
    });
  }

  const data: Array<PhoneInfo> = await response.data.data;
  return { data: data };
});

const initialState: PhonesState & GenericFetchType = {
  phones: [],
  status: "idle",
  error: null,
};

const phonesSlice = createSlice({
  name: "phones",

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchPhones.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });

    builder.addCase(fetchPhones.fulfilled, (state, { payload }) => {
      state.phones = [...payload.data];
      state.status = "idle";
    });

    builder.addCase(fetchPhones.rejected, (state, { payload }) => {
      if (payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export default phonesSlice.reducer;
