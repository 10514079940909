import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActive } from "../../store/slices/modalSlice";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { phoneRegExp } from "../../constants/Regs";
import { useEffect, useState } from "react";
import axios from "axios";
import Cross from "../../images/GeneralImages/cross.png";
import { AnimatePresence, motion } from "framer-motion";
import { tabsAnimation } from "../../animations/animations";
import { fetchPhones } from "../../store/slices/phonesSlice";
import { PhoneInfo } from "../../types/SliceTypes/phonesState";
import Telephone from "../../images/LocationsImages/telephone.svg";
import { Loader } from "../Loader/Loader";

export const Modal = () => {
  const lang = useAppSelector((state) => state.locale);
  const phones = useAppSelector((state) => state.phones.phones);
  const isActive = useAppSelector((state) => state.modal.active);
  const [isSuccess, setSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    FullName: Yup.string()
      .min(2, t("formsWarnings.tooShort"))
      .max(50, t("formsWarnings.tooLong"))
      .required(t("formsWarnings.symbols")),
    PhoneNumber: Yup.string()
      .matches(phoneRegExp, t("formsWarnings.phoneNumber"))
      .required(t("formsWarnings.enterPhoneNumber")),
    Email: Yup.string()
      .email(t("formsWarnings.invalidEmail"))
      .required(t("formsWarnings.incorrectEmailFormat")),
  });

  useEffect(() => {
    dispatch(fetchPhones({ language: lang.localStorageValue }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchPhones({ language: lang.localStorageValue }));
  }, [lang.localStorageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AnimatePresence>
      {isActive && (
        <motion.div
          initial="hidden"
          animate="visible"
          exit="hidden"
          variants={tabsAnimation}
          className="h-screen w-screen fixed top-0 left-0 bg-[rgba(0,0,0,0.6)] z-[100] flex items-center justify-center"
        >
          <div className="py-[1.563rem] px-[3.125rem] bg-[#181a20] max-h-[70vh] overflow-auto">
            <div className="flex items-center	justify-between">
              <p className="text-[1.375rem] font-semibold text-white">
                {t("modal.phoneUs")}
              </p>
              <div>
                <img
                  src={Cross}
                  alt="cross"
                  className="w-4 cursor-pointer hover:scale-125 duration-300"
                  onClick={() => {
                    setSuccess(false);
                    dispatch(setActive(false));
                  }}
                />
              </div>
            </div>
            {phones.length ? (
              <div className="mt-5 grid md:grid-cols-2">
                {phones.map((phone: PhoneInfo, index: number) => {
                  return (
                    <div className="flex border-b pl-1 pr-4 py-2 justify-between" key={index}>
                      <p className="font-semibold text-white">{phone.name}</p>
                      <div className="flex items-center cursor-pointer">
                        <img
                          src={Telephone}
                          alt="tel"
                          className="w-3.5 mx-2.5"
                        />
                        <a href={`tel:${phone.phoneNumber}`} className="text-[#c99400]">{phone.phoneNumber}</a>
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <Loader height="120" width="600" />
            )}
            <div className="mt-5">
              <Formik
                initialValues={{
                  FullName: "",
                  PhoneNumber: "",
                  Email: "",
                  Message: "",
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={(values, { resetForm }) => {
                  setInProgress(true);
                  setSuccess(false);
                  axios
                    .post(
                      "https://w5z5vcgwdvh6ksokywe3j35fvy0ajmsg.lambda-url.us-east-1.on.aws/api/forms/lets-talk",
                      values,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((response) => {
                      console.log("Success:", response.data);
                      setSuccess(true);
                      setInProgress(false);
                      resetForm();
                    })
                    .catch((error) => {
                      console.error("Something went wrong", error);
                      setSuccess(false);
                      setInProgress(false);
                    });
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <article className="mb-[2rem]">
                      <div className="mb-[1.563rem]">
                        <div className="relative">
                          <label htmlFor="FullName" className="form-label">
                            {t("modal.fullName")}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="FullName"
                            name="FullName"
                            type="text"
                            className={`form-input shadow-inputShadow ${
                              touched.FullName &&
                              errors.FullName &&
                              "!border-[#eb5757]"
                            }`}
                          />
                          {touched.FullName && errors.FullName && (
                            <span className="form-req-info">
                              {errors.FullName}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="grid gap-[1.875rem] mb-[1.563rem] md:grid-cols-2">
                        <div className="relative">
                          <label htmlFor="PhoneNumber" className="form-label">
                            {t("modal.phoneNumber")}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="PhoneNumber"
                            name="PhoneNumber"
                            type="tel"
                            className={`form-input shadow-inputShadow ${
                              touched.PhoneNumber &&
                              errors.PhoneNumber &&
                              "!border-[#eb5757]"
                            }`}
                          />
                          {touched.PhoneNumber && errors.PhoneNumber && (
                            <span className="form-req-info">
                              {errors.PhoneNumber}
                            </span>
                          )}
                        </div>
                        <div className="relative">
                          <label htmlFor="Email" className="form-label">
                            {t("modal.mail")}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="Email"
                            name="Email"
                            type="text"
                            className={`form-input shadow-inputShadow ${
                              touched.Email &&
                              errors.Email &&
                              "!border-[#eb5757]"
                            }`}
                          />
                          {touched.Email && errors.Email && (
                            <span className="form-req-info">
                              {errors.Email}
                            </span>
                          )}
                        </div>
                      </div>
                    </article>
                    <article className="mb-[2rem]">
                      <label htmlFor="Message" className="form-label">
                        {t("modal.message")}
                      </label>
                      <Field
                        as="textarea"
                        rows="40"
                        name="Message"
                        id="Message"
                        className="form-input shadow-inputShadow !h-20 resize-none"
                      />
                    </article>
                    <div className="relative">
                      {inProgress ? (
                        <Loader height="41" width="100%" />
                      ) : (
                        <button
                          type="submit"
                          className="btn-style font-medium block mt-[1.875rem] rounded-[3.125rem] m-auto min-w-[6.25rem] hover:bg-white hover:text-black duration-500"
                        >
                          {isSuccess
                            ? t("modal.successfully")
                            : t("modal.submit")}
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
