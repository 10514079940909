import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { LocationsLinkBlock } from "../../components/LocationsLinkBlock/LocationsLinkBlock";
import * as Yup from "yup";
import { Field, Form, Formik } from "formik";
import axios from "axios";
import { useEffect, useState } from "react";
import { phoneRegExp } from "../../constants/Regs";
import { motion } from "framer-motion";
import { titleAnimation } from "../../animations/animations";
import { Loader } from "../../components/Loader/Loader";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export const ContactUs = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const [isSuccess, setSuccess] = useState(false);
  const [inProgress, setInProgress] = useState(false);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 1024;

  const DisplayingErrorMessagesSchema = Yup.object().shape({
    ApplicantFirstName: Yup.string()
      .min(2, t("formsWarnings.tooShort"))
      .max(50, t("formsWarnings.tooLong"))
      .required(t("formsWarnings.symbols")),
    ApplicantLastName: Yup.string()
      .min(2, t("formsWarnings.tooShort"))
      .max(50, t("formsWarnings.tooLong"))
      .required(t("formsWarnings.symbols")),
    ApplicantEmail: Yup.string()
      .email(t("formsWarnings.invalidEmail"))
      .required(t("formsWarnings.incorrectEmailFormat")),
    ApplicantPhoneNumber: Yup.string()
      .matches(phoneRegExp, t("formsWarnings.phoneNumber"))
      .required(t("formsWarnings.enterPhoneNumber")),
    IsBoxChecked: Yup.boolean()
      .oneOf([true], t("formsWarnings.fieldMustBeMarked"))
      .required(t("formsWarnings.fieldRequired")),
  });

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[1.875rem] min-h-[7.813rem] flex items-center mainPageLG:px-[15px]">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >
              {t("contactUs.contactTitle")}
            </motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("contactUs.contactTitle")}
            </div>
          )}
        </div>
      </section>
      <section className="py-[15px]">
        <div className="container-standard px-[1.875rem] text-[#ddb81e] text-xs font-bold mainPageLG:px-[15px]">
          <NavLink to="/">HOME</NavLink>
          <span> / </span>
          <span>{t("contactUs.contactTitle")}</span>
        </div>
      </section>
      <section className="mt-[0.625rem] upMD:mt-[2.813rem]">
        <div className="container-standard px-[1.875rem] flex flex-col upMD:flex-row upMD:justify-between upMD:px-[50px] mainPageLG:px-[7.5rem]">
          <div className="upMD:w-contactCalc mb-[2.813rem]">
            <h1>{t("contactUs.contactFDC")}</h1>
            <p className="text-base mt-2.5 text-white">
              {t("contactUs.contactUsToday")}
            </p>
            <div className="mt-10">
              <Formik
                initialValues={{
                  ApplicantFirstName: "",
                  ApplicantLastName: "",
                  ApplicantEmail: "",
                  ApplicantPhoneNumber: "",
                  SelectContactMethod: "",
                  Message: "",
                  IsBoxChecked: false,
                }}
                validationSchema={DisplayingErrorMessagesSchema}
                onSubmit={(values, { resetForm }) => {
                  setInProgress(true);
                  setSuccess(false);
                  console.log(values);
                  axios
                    .post(
                      "https://w5z5vcgwdvh6ksokywe3j35fvy0ajmsg.lambda-url.us-east-1.on.aws/api/forms/contact-us",
                      values,
                      {
                        headers: {
                          "Content-Type": "multipart/form-data",
                        },
                      }
                    )
                    .then((response) => {
                      console.log("Success:", response.data);
                      setSuccess(true);
                      setInProgress(false);
                      resetForm();
                    })
                    .catch((error) => {
                      console.error("Something went wrong", error);
                      setSuccess(false);
                      setInProgress(false);
                    });
                }}
              >
                {({ errors, touched }) => (
                  <Form>
                    <article className="mb-[2rem]">
                      <div className="grid gap-[1.875rem] mb-[1.563rem] md:grid-cols-2">
                        <div className="relative">
                          <label
                            htmlFor="ApplicantFirstName"
                            className="form-label"
                          >
                            {t("contactUs.firstName")}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="ApplicantFirstName"
                            name="ApplicantFirstName"
                            type="text"
                            className={`form-input shadow-inputShadow ${
                              touched.ApplicantFirstName &&
                              errors.ApplicantFirstName &&
                              "!border-[#eb5757]"
                            }`}
                          />
                          {touched.ApplicantFirstName &&
                            errors.ApplicantFirstName && (
                              <span className="form-req-info">
                                {errors.ApplicantFirstName}
                              </span>
                            )}
                        </div>
                        <div className="relative">
                          <label
                            htmlFor="ApplicantLastName"
                            className="form-label"
                          >
                            {t("contactUs.lastName")}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="ApplicantLastName"
                            name="ApplicantLastName"
                            type="text"
                            className={`form-input shadow-inputShadow ${
                              touched.ApplicantLastName &&
                              errors.ApplicantLastName &&
                              "!border-[#eb5757]"
                            }`}
                          />
                          {touched.ApplicantLastName &&
                            errors.ApplicantLastName && (
                              <span className="form-req-info">
                                {errors.ApplicantLastName}
                              </span>
                            )}
                        </div>
                      </div>
                      <div className="grid gap-[1.875rem] mb-[1.563rem] md:grid-cols-2">
                        <div className="relative">
                          <label
                            htmlFor="ApplicantEmail"
                            className="form-label"
                          >
                            {t("contactUs.email")}{" "}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="ApplicantEmail"
                            name="ApplicantEmail"
                            type="text"
                            className={`form-input shadow-inputShadow ${
                              touched.ApplicantEmail &&
                              errors.ApplicantEmail &&
                              "!border-[#eb5757]"
                            }`}
                          />
                          {touched.ApplicantEmail && errors.ApplicantEmail && (
                            <span className="form-req-info">
                              {errors.ApplicantEmail}
                            </span>
                          )}
                        </div>
                        <div className="relative">
                          <label
                            htmlFor="ApplicantPhoneNumber"
                            className="form-label"
                          >
                            {t("contactUs.phoneNumber")}
                            <span className="text-[#eb5757]">*</span>
                          </label>
                          <Field
                            id="ApplicantPhoneNumber"
                            name="ApplicantPhoneNumber"
                            type="tel"
                            className={`form-input shadow-inputShadow ${
                              touched.ApplicantPhoneNumber &&
                              errors.ApplicantPhoneNumber &&
                              "!border-[#eb5757]"
                            }`}
                          />
                          {touched.ApplicantPhoneNumber &&
                            errors.ApplicantPhoneNumber && (
                              <span className="form-req-info">
                                {errors.ApplicantPhoneNumber}
                              </span>
                            )}
                        </div>
                      </div>
                    </article>
                    <article className="mb-[2rem]">
                      <div className="relative mb-[1.563rem]">
                        <label htmlFor="ApplicantEmail" className="form-label">
                          {t("contactUs.preferredContactMethod")}
                        </label>
                        <Field
                          as="select"
                          id="SelectContactMethod"
                          name="SelectContactMethod"
                          className={`form-input shadow-inputShadow`}
                        >
                          <option
                            value=""
                            label={t("contactUs.option1")}
                            disabled
                            selected
                            className="text-[#bfbfbf]"
                          />
                          <option value={t("contactUs.option2")} label={t("contactUs.option2")} />
                          <option value={t("contactUs.option3")} label={t("contactUs.option3")} />
                        </Field>
                      </div>
                    </article>
                    <article className="mb-[2rem]">
                      <label htmlFor="message" className="form-label">
                        {t("contactUs.message")}
                      </label>
                      <Field
                        as="textarea"
                        rows="40"
                        name="Message"
                        id="Message"
                        className="form-input shadow-inputShadow !h-20 resize-none"
                      />
                    </article>
                    <article className="mb-[2.813rem] text-sm text-white">
                      <p className="my-3.5">
                        {t("contactUs.groupIsCommittedToProtecting")}
                        {t("contactUs.privacyPolicy")}
                      </p>
                      <p className="my-3.5">
                        {t("contactUs.bySubmittingTheFormYouAgree")}
                      </p>
                      <p className="my-3.5 font-medium">
                        {t("contactUs.marketingConsent")}
                      </p>
                      <p className="my-3.5">
                        {t("contactUs.wedLoveToKeepInTouchWithYou")}
                      </p>
                      <div className="relative mb-6">
                        <label className="flex items-center ">
                          <Field type="checkbox" name="IsBoxChecked" />
                          <span className="inline-block pl-2">
                            <b>{t("contactUs.iConsent")}</b>
                            {t("contactUs.fdcGroupСontacting")}
                          </span>
                        </label>
                        {touched.IsBoxChecked && errors.IsBoxChecked && (
                          <div className="form-req-info !bottom-[-95%]">
                            {errors.IsBoxChecked}
                          </div>
                        )}
                      </div>
                      <p className="my-3.5">
                        {t("contactUs.youCanUnsubscribe")}
                      </p>
                      <p className="my-3.5">{t("contactUs.byPost")}</p>
                      <p className="my-3.5">
                        {t("contactUs.byEmail")}
                        <a href="mailto: communication@pacem.io" className="underline pl-1 text-[#0000EE]">
                          communication@pacem.io
                        </a>
                      </p>
                      <p className="my-3.5">{t("contactUs.byPhone")}</p>
                      <p className="my-3.5">
                        {t("contactUs.byClickingSubmitBelow")}
                      </p>
                    </article>
                    <div className="relative">
                      {inProgress ? (
                        <Loader height="41" width="100%" />
                      ) : (
                        <button
                          type="submit"
                          className="btn-style block mt-[1.875rem] font-medium rounded-[3px] min-w-[6.25rem] hover:bg-white hover:text-black duration-500"
                        >
                          {isSuccess
                            ? t("contactUs.successfully")
                            : t("contactUs.submit")}
                        </button>
                      )}
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
          <LocationsLinkBlock />
        </div>
      </section>
    </main>
  );
};
