import { IToggleMobMenu } from "./ToggleMobMenuInterface";
import triangle from "../../images/GeneralImages/triangle.svg";
import { NavLink } from "react-router-dom";
import { subsectionType } from "../../types/menuTypes";

export const ToggleMobMenu = ({
  index,
  openIndex,
  toggleMenu,
  title,
  closeNav,
}: IToggleMobMenu) => {
  const isOpen = index === openIndex;

  const setCloseZeroIndex = () => {
    toggleMenu(null);
    closeNav();
  };

  return (
    <div className="min-h-[3.375rem]">
      {title.subTittles.length ? (
        <div className="relative border-b-4 border-b-[#d3b53bd1] flex justify-between">
          <NavLink
            to={`${title.route}`}
            className="px-2.5 my-2.5 block font-bold text-xl text-white"
            onClick={closeNav}
          >
            {title.title}
          </NavLink>
          <button
            className={`toggle-expand-btn-menu w-auto h-auto ${
              isOpen ? "expanded" : ""
            }`}
            onClick={() => toggleMenu(index)}
          >
            <img src={triangle} alt="marker" />
          </button>
        </div>
      ) : (
        <div className="border-b-4 border-b-[#d3b53bd1] flex">
          <NavLink
            to={`${title.route}`}
            className="px-2.5 my-2.5 block font-bold text-xl text-white w-full"
            onClick={closeNav}
          >
            {title.title}
          </NavLink>
        </div>
      )}
      {title.subTittles.length ? (
        <ul
          className={`overflow-hidden transition-all duration-700 ease-in-out ${
            isOpen ? "max-h-96" : "max-h-0"
          }`}
        >
          {title.subTittles.map((item: subsectionType) => {
            return (
              <li
                key={item.subTittle}
                className="pl-4 py-1 text-lg font-semibold text-white"
              >
                <NavLink to={`${item.route}`} onClick={setCloseZeroIndex}>
                  {item.subTittle}
                </NavLink>
              </li>
            );
          })}
        </ul>
      ) : null}
    </div>
  );
};
