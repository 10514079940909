import { useTranslation } from "react-i18next";

export const Privacy = () => {
  const { t } = useTranslation();

  return (
    <main>
      <section className="min-h-[7.813rem]">
        <div className="container-standard px-[1.875rem] min-h-[7.813rem] pb-20 text-white upMD:px-[50px] mainPageLG:px-[7.5rem]">
          <h1 className="mt-[3rem]">{t("privatPolicy.title")}</h1>
          <p className="mt-[1.875rem]">{t("privatPolicy.p")}</p>
          <h2 className="mt-[1.875rem]">{t("privatPolicy.title1")}</h2>
          <p className="mt-[15px]">{t("privatPolicy.p1")}</p>
          <h2 className="mt-[1.875rem]">{t("privatPolicy.title2")}</h2>
          <p className="mt-[15px]">{t("privatPolicy.p2")}</p>
          <h2 className="mt-[1.875rem]">{t("privatPolicy.title3")}</h2>
          <p className="mt-[15px]">{t("privatPolicy.p3")}</p>
          <h2 className="mt-[1.875rem]">{t("privatPolicy.title4")}</h2>
          <p className="mt-[15px]">{t("privatPolicy.p4")}</p>
          <h2 className="mt-[1.875rem]">{t("privatPolicy.title5")}</h2>
          <p className="mt-[15px]">{t("privatPolicy.p5")}</p>
          <h2 className="mt-[1.875rem]">{t("privatPolicy.title6")}</h2>
          <p className="mt-[15px]">{t("privatPolicy.p6")}</p>
          <h2 className="mt-[1.875rem]">{t("privatPolicy.title7")}</h2>
          <p className="mt-[15px]">{t("privatPolicy.p7")}</p>
          <p>{t("privatPolicy.p8")}</p>
        </div>
      </section>
    </main>
  );
};
