import Slider from "react-slick";
import first from "../../images/MainImages/mainSlides/first.jpg";
import second from "../../images/MainImages/mainSlides/second.jpg";
import third from "../../images/MainImages/mainSlides/third.jpg";

const settings = {
  variableWidth: true,
  infinite: true,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  slidesToShow: 1,
  slidesToScroll: 1,
  dots: true,
  pauseOnFocus: false,
  pauseOnHover: false,
};

export const MainCarousel = () => {
  return (
    <div className="overflow-hidden h-[30.75rem] upMD:h-[43.75rem]">
      <Slider {...settings}>
        <div className="w-full relative">
          <img
            className="w-[100vw] h-[30.75rem] brightness-50 object-none upMD:object-fill upMD:h-[43.75rem]"
            src={third}
            alt="#"
          />
          <div className="w-full absolute top-[40%] left-0 text-center text-yellow-color uppercase font-bold text-4xl md:text-6xl mainPageLG:top-[30%] mainPageLG:text-8xl">
            <p className="mb-4 border-solid border-yellow-color border-b-4 w-full mx-auto h-[3.8rem] md:w-[43.75rem] md:h-[5.375rem] mainPageLG:mb-[3.125rem] mainPageLG:h-[9.375rem]">
              PACEM
            </p>
            <p>About trading</p>
          </div>
        </div>
        <div className="w-full relative">
          <img
            className="w-[100vw] h-[30.75rem] brightness-50 object-none upMD:object-fill upMD:h-[43.75rem]"
            src={second}
            alt="#"
          />
          <div className="w-full absolute top-[48%] left-0 text-center xsm:top-[48%] mainPageLG:top-[40%]">
            <p className="text-yellow-color uppercase font-bold w-full mx-auto text-4xl md:text-6xl mainPageLG:text-8xl">
              in the heart of...
            </p>
          </div>
        </div>
        <div className="w-full relative">
          <img
            className="w-[100vw] h-[30.75rem] brightness-50 object-none upMD:object-fill upMD:h-[43.75rem]"
            src={first}
            alt="#"
          />
          <div className="w-full absolute top-[48%] left-0 text-center xsm:top-[48%] mainPageLG:top-[40%]">
            <p className="text-yellow-color tracking-widest uppercase font-bold w-full mx-auto text-4xl md:text-6xl mainPageLG:text-9xl mainPageLG:w-[56.25rem]">
              Ireland
            </p>
          </div>
        </div>
      </Slider>
    </div>
  );
};
