import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";

export const ErrorPage = () => {
  const { t } = useTranslation();

  return (
    <main className="pb-[7.5rem]">
      <div className="max-w-full h-[10vh]">
        <div className="container-standard flex flex-col items-center !my-20 justify-center px-[15px] upMD:px-[3.125rem] mainPage:px-0">
          <h1> {t("notFound.pageNotFound")}</h1>
          <NavLink
            to="/"
            className="btn-style !max-w-max mt-[1.875rem] rounded-[3.125rem] mb-[2.5rem] hover:bg-white hover:text-black duration-500"
          >
            {t("notFound.goToMain")}
          </NavLink>
        </div>
      </div>
    </main>
  );
};
