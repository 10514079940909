import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import Telephone from "../../images/LocationsImages/telephone.svg";

export const LocationsLinkBlock = () => {
  const { t } = useTranslation();

  return (
    <div className="mb-[1.875rem] upMD:w-[15.625rem]">
      <div className="bg-[#e8e0c5] p-[1.875rem]">
        <h3 className="text-black">{t("contactUs.contactLoc")}</h3>
        <p className="text-base	mt-2.5">{t("contactUs.officesLocated")}</p>
        <p className="text-base mt-[1.563rem]">{t("contactUs.contactHead")}</p>
        <div className="leading-6 text-[#c99400] flex items-center">
          <div>
            <img src={Telephone} alt="tel" className="w-3.5" />
          </div>
          <p className="ml-2">+353 899761899</p>
        </div>
        <NavLink
          to="../locations"
          className="bg-[#fef6d8] font-medium rounded-[3.125rem] mt-[1.563rem] w-full text-center text-[#c99400] px-7 py-2 hover:bg-white hover:text-black duration-500 inline-block"
        >
          {t("contactUs.viewLocations")}
        </NavLink>
      </div>
    </div>
  );
};
