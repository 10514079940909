import { OurTeammate } from "../components/OurTeammate/OurTeammate";
import { OurTeamInfo } from "../types/SliceTypes/ourTeamState";

export const setOurTeammates = (teammates: Array<OurTeamInfo>) => {
  return (
    <>
      {teammates.map((teammate: OurTeamInfo, index: number) => {
        return (
          <OurTeammate
            name={teammate.name}
            image={teammate.image}
            key={`${teammate.name} + ${index}`}
            styles="w-full sm:w-[45%] mainPageLG:w-[32%]"
          />
        );
      })}
    </>
  );
};
