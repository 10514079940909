import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import ukflag from "../../images/LanguagesImages/UKflag.svg";
import ruflag from "../../images/LanguagesImages/ruflag.png";
import esflag from "../../images/LanguagesImages/spain.png";
import gerflag from "../../images/LanguagesImages/gerflag.png"
import fraFlag from "../../images/LanguagesImages/fraflag.png"
import { useTranslation } from "react-i18next";
import { setLocale } from "../../store/slices/localeSlice";
import { menuType, subsectionType } from "../../types/menuTypes";
import { useEffect, useState } from "react";
import Logo from "../../images/GeneralImages/Logo.svg";
import { LayoutGroup, motion } from "framer-motion";
import { ActiveLine } from "../ActiveLine/ActiveLine";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setCurrentRoute, setSecRoute } from "../../store/slices/routerSlice";
import { MobileMenuButton } from "../MobileMenuBtn/MobileMenuBtn";
import { BurgerMenu } from "../BurgerMenu/BurgerMenu";

export const Header = () => {
  const [showMenu, setShowMenu] = useState(false);
  const { currentRoute, secRoute } = useAppSelector((state) => state.router);
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const { i18n, t } = useTranslation();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const titles = t("titles", { returnObjects: true }) as Array<menuType>;
  const isDesktop = screenWidth > 1100;

  const changeLanguage = (lng: string) => {
    dispatch(setLocale(lng));
    i18n.changeLanguage(lng);
  };

  const createMenu = (titles: Array<menuType>) => {
    return (
      <>
        {titles.map((title: menuType) => {
          return (
            <motion.li className="group relative" key={title.title}>
              <NavLink
                to={`${title.route}`}
                className={`transition text-white ease-in-out block px-2.5 hover:text-[#ddb81e] font-medium hover:scale-110 duration-200 ${currentRoute === title.route
                  ? "!text-[#ddb81e] scale-110"
                  : ""
                  }`}
              >
                {title.title}
              </NavLink>
              {currentRoute === title.route && <ActiveLine />}
              {title.subTittles.length ? (
                <ul className="z-50 invisible bg-[#181a20] text-[#757575] pr-8 py-2 min-w-[18.75rem] absolute top-[2rem] transition-all opacity-0 group-hover:visible group-hover:opacity-100 group-hover:translate-y-2 ease-in duration-200 rounded shadow-menuBlockShadow">
                  <p className="text-xl text-[#fff] px-2.5 py-3">
                    {title.title}
                  </p>
                  {title.subTittles.map((item: subsectionType) => {
                    return (
                      <li key={item.subTittle}>
                        <NavLink
                          to={`${item.route}`}
                          className={`block text-white font-semibold px-2.5 py-2 hover:text-[16px] hover:!text-[#ddb81e] ease-in-out duration-100 ${secRoute === item.route ? "!text-[#ddb81e]" : ""
                            }`}
                        >
                          {item.subTittle}
                        </NavLink>
                      </li>
                    );
                  })}
                </ul>
              ) : null}
            </motion.li>
          );
        })}
      </>
    );
  };

  const closeNav = () => {
    setShowMenu(false);
  };

  useEffect(() => {
    if (titles[3].subTittles.some((item) => item.route === location.pathname)) {
      dispatch(setCurrentRoute("/what-we-do"));
    } else {
      dispatch(setCurrentRoute(location.pathname));
    }
    dispatch(setSecRoute(location.pathname));
  }, [currentRoute, dispatch, location]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (showMenu) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [showMenu]);

  return (
    <header className="z-30 bg-[#000000] fixed top-[0%] bottom-auto left-[0%] right-[0%] z-20 text-sm">
      <div className="z-[3] flex justify-between items-center px-[15px] container-standard relative top-0 overflow-visible mainPage:px-0">
        {isDesktop ? (
          <div className="z-[20] w-[6.875rem]">
            <NavLink to="/" onClick={closeNav}>
              <img src={Logo} alt="Emblem" className="w-full" />
            </NavLink>
          </div>
        ) : (
          <div className="w-full bg-[#000000] z-[20] flex justify-between items-center">
            <div className="z-[20] w-[6.875rem]">
              <NavLink to="/" onClick={closeNav}>
                <img src={Logo} alt="Emblem" className="w-full" />
              </NavLink>
            </div>
            <MobileMenuButton showMenu={showMenu} setShowMenu={setShowMenu} />
          </div>
        )}
        <nav
          className="flex items-center flex-wrap relative tables:w-[85%] pr-4"
          aria-label="desktop-header-menu"
        >
          <LayoutGroup>
            <motion.ul className="text-sm hidden whitespace-nowrap justify-end w-10/12 relative tables:!flex">
              {createMenu(titles)}
            </motion.ul>
          </LayoutGroup>
          <div className="flex hidden space-x-3 w-2/12 items-center pl-3 justify-end tables:!flex">
            <div>
              <img
                src={ukflag}
                alt="flag"
                className="w-[1.5rem] transition ease-in-out hover:scale-150 duration-300 cursor-pointer shadow-flagShadow"
                onClick={() => changeLanguage("en")}
              />
            </div>
            <div>
              <img
                src={ruflag}
                alt="flag"
                className="w-[1.5rem] transition ease-in-out hover:scale-150 duration-300 cursor-pointer shadow-flagShadow"
                onClick={() => changeLanguage("ru")}
              />
            </div>
            <div>
              <img
                src={gerflag}
                alt="flag"
                className="w-[1.5rem] transition ease-in-out hover:scale-150 duration-300 cursor-pointer shadow-flagShadow"
                onClick={() => changeLanguage("ge")}
              />
            </div>
            <div>
              <img
                src={fraFlag}
                alt="flag"
                className="w-[1.5rem] transition ease-in-out hover:scale-150 duration-300 cursor-pointer shadow-flagShadow"
                onClick={() => changeLanguage("fr")}
              />
            </div>
            <div>
              <img
                src={esflag}
                alt="flag"
                className="w-[1.5rem] transition ease-in-out hover:scale-150 duration-300 cursor-pointer shadow-flagShadow"
                onClick={() => changeLanguage("es")}
              />
            </div>
          </div>
          <BurgerMenu showMenu={showMenu} closeNav={closeNav} />
        </nav>
      </div>
    </header>
  );
};
