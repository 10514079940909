import { motion } from "framer-motion";
import { Footer } from "../components/Footer/Footer";
import { Header } from "../components/Header/Header";
import { HeaderLogo } from "../components/HeaderLogo/HeaderLogo";
import { Modal } from "../components/Modal/Modal";
import { useAppSelector } from "../store/hooks";
import "./App.css";
import { PagesRouting } from "./PagesRouting/PagesRouting";
import { siteAnimation } from "../animations/animations";

function App() {
  const isFirstLoad = useAppSelector((state) => state.logoFirst.isFirstLoad);

  return (
    <>
      {isFirstLoad ? (
        <HeaderLogo />
      ) : (
        <motion.div {...siteAnimation}>
          <Header />
          <PagesRouting />
          <Footer />
          <Modal />
        </motion.div>
      )}
    </>
  );
}

export default App;
