export const arrOfBlocksAnimation = {
  hidden: {
    y: 20,
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

export const arrOfCareerAnimation = {
  hidden: {
    y: -200,
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: { delay: custom * 0.2 },
  }),
};

export const bigWhatWeDoImgAnimation = {
  hidden: {
    x: -200,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { duration: 1 },
  },
};

export const arrOfWhatWeDoUnderAnimation = {
  hidden: {
    y: 200,
    opacity: 0,
  },
  visible: (custom: number) => ({
    y: 0,
    opacity: 1,
    transition: { duration: custom * 0.3 },
  }),
};

export const arrOfWhatWeDoRightAnimation = {
  hidden: {
    x: 200,
    opacity: 0,
  },
  visible: (custom: number) => ({
    x: 0,
    opacity: 1,
    transition: { duration: custom, delay: 0.5 },
  }),
};

export const tabsAnimation = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: { duration: 0.5 },
  },
};

export const titleAnimation = {
  hidden: {
    x: 1000,
    opacity: 0,
  },
  visible: {
    x: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 40 },
  },
};

export const animationLogo = {
  initial: { opacity: 0, scale: 0.5, rotateY: 360, translateZ: -1000 },
  animate: { opacity: 1, scale: 1, rotateY: 0, translateZ: 0 },
  exit: { opacity: 0, scale: 0.5, rotateY: -360, translateZ: -1000 },
  transition: { duration: 2, ease: "easeInOut" },
};

export const siteAnimation = {
  initial: { opacity: 0 },
  animate: { opacity: 1 },
  exit: { opacity: 0 },
  transition: { duration: 1, ease: "easeInOut" },
};
