import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { setActive } from "../../store/slices/modalSlice";
import Telephone from "../../images/LocationsImages/telephone.svg";
import { useTranslation } from "react-i18next";

export const ActivateModal = () => {
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 768;

  return (
    <button
      className={`fixed bg-[#fef6d8] right-[1%] top-[90%] py-2.5 px-5 cursor-pointer border rounded-full z-10 text-[#c99400] md:py-1.5 md:right-0 md:rounded-none mainPageLG:top-[50%]`}
      onClick={() => {
        dispatch(setActive(true));
      }}
    >
      <img src={Telephone} alt="tel" className={`w-3.5 inline mr-0 md:mr-2.5`} />
      {isDesktop && (
        <span className="font-semibold">{t("activateModalBtn")}</span>
      )}
    </button>
  );
};
