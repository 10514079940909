import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { modalState } from "../../types/SliceTypes/modalState";

const initialState: modalState = {
  active: false,
};

const modalSlice = createSlice({
  name: "modal",

  initialState,

  reducers: {
    setActive: (state, action: PayloadAction<boolean>) => {
      state.active = action.payload;
    },
  },
});

export const { setActive } = modalSlice.actions;
export default modalSlice.reducer;
