import { useTranslation } from "react-i18next";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { IWhatWeDo } from "./WhatWeDoBoxInterface";
import { ForwardedRef, forwardRef } from "react";
import { motion } from "framer-motion";

export const WhatWeDoBox = forwardRef(
  (
    { info, img, link, infoStyles }: IWhatWeDo,
    ref: ForwardedRef<HTMLLIElement>
  ) => {
    const [isHovered, setIsHovered] = useState(false);
    const { t } = useTranslation();

    const handleMouseEnter = () => {
      setIsHovered(true);
    };

    const handleMouseLeave = () => {
      setIsHovered(false);
    };

    return (
      <li
        ref={ref}
        className={`w-full h-[19.375rem] bg-[#0e0f11] border-solid border border-[#fcd53533] flex items-center justify-center sm:w-1/3 ${link ? "whatWeDoBox" : ""
          }`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleMouseEnter}
        onTouchEnd={handleMouseLeave}
      >
        <div className="text-center">
          <div>
            {img && <img src={img} alt="direction" className="mb-5 m-auto" />}
            <h3
              className={`text-[#ddb81e] text-[1rem] duration-300 ${infoStyles} ${isHovered && link ? "mb-12" : ""
                }`}
            >
              {info}
            </h3>
          </div>
          {link && (
            <NavLink
              to={link}
              className={`bg-[#fef6d8] font-medium rounded-[3.125rem] text-[#c99400] px-9 py-2 hover:bg-white hover:text-black duration-500 absolute readMoreBtn ${isHovered ? "mt-2 opacity-100" : "opacity-0"
                }`}
            >
              {t("mainPage.readMore")}
            </NavLink>
          )}
        </div>
      </li>
    );
  }
);

export const MWhatWeDoBox = motion(WhatWeDoBox);
