import { ThreeCircles } from "react-loader-spinner";
import { ILoader } from "./LoaderInterface";

export const Loader = ({ height, width, styles }: ILoader) => {
  return (
    <div className={`container-standard h-full w-full ${styles}`}>
      <ThreeCircles
        height={height}
        width={width}
        visible={true}
        ariaLabel="three-circles-rotating"
        outerCircleColor="#c99400"
        innerCircleColor="#e8e0c5"
        middleCircleColor="#ddb81e"
      />
    </div>
  );
};
