import { NavLink } from "react-router-dom";
import { ImgBoxInterface } from "../../Interfaces/ImagesBoxInterface";
import { ForwardedRef, forwardRef } from "react";
import { motion } from "framer-motion";

export const WhatWeDoImagesBox = forwardRef(
  (
    { icon, img, firstRow, background, link, styles }: ImgBoxInterface,
    ref: ForwardedRef<HTMLDivElement>
  ) => {
    return (
      <div
        ref={ref}
        className={`${styles} before:content-['>'] before:absolute before:bottom-[12px] before:right-[15px] before:text-[1.375rem] before:text-white before:z-[21] w-full h-[3.125rem] relative p-2.5 overflow-hidden md:before:content-[''] md:h-[21.4vw] xl:h-[17.188rem]`}
      >
        <NavLink to={`.${link}`}>
          {background && (
            <div
              className="absolute z-20 h-full top-0 left-0 opacity-80 ease-in duration-150 hover:opacity-100 hover:ease-in hover:duration-150 cursor-pointer w-full"
              style={{ background: background }}
            ></div>
          )}
          {img && (
            <img
              src={img}
              alt="WhatWeDoBG"
              className="transform-image block scale-110 absolute top-0 left-0 h-full min-w-[115%] z-10"
            ></img>
          )}

          <div className="absolute flex items-center bottom-0 pl-1 pb-2.5 z-20 pointer-events-none md:items-start md:block mainPageLG:pl-5">
            {icon && (
              <img
                className="transform-icon mb-0 w-[2rem] h-[2rem] md:mb-[0.65rem] md:w-fit md:h-fit mainPageLG:mb-5"
                src={icon}
                alt="WhatWeDoIcon"
              />
            )}
            {firstRow && (
              <span className="text-white ml-[0.8rem] font-medium uppercase text-[0.6rem] md:ml-0 whatWeDoPage:text-[0.8rem] mainPageLG:text-[0.9rem] xl:text-lg">
                {firstRow}
              </span>
            )}
          </div>
        </NavLink>
      </div>
    );
  }
);

export const MWhatWeDoImagesBox = motion(WhatWeDoImagesBox);
