import { useTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { useEffect } from "react";
import { fetchLocations } from "../../store/slices/locationsSlice";
import { OneLocation } from "../../components/OneLocation/OneLocation";
import { LocationGroup } from "../../types/SliceTypes/locationsState";
import { Loader } from "../../components/Loader/Loader";
import { motion } from "framer-motion";
import { titleAnimation } from "../../animations/animations";
import { setScreenWidth } from "../../store/slices/screenWidthSlice";

export const Locations = () => {
  const locations = useAppSelector((state) => state.locations.locations);
  const screenWidth = useAppSelector((state) => state.screenWidth.siteWidth);
  const lang = useAppSelector((state) => state.locale);
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const isDesktop = screenWidth > 1024;

  useEffect(() => {
    dispatch(fetchLocations({ language: lang.localStorageValue }));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(fetchLocations({ language: lang.localStorageValue }));
  }, [lang.localStorageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleResize = () => {
      dispatch(setScreenWidth(window.innerWidth));
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [dispatch]);

  return (
    <main>
      <section className="min-h-[7.813rem] bg-[#d3b53b]">
        <div className="container-standard px-[30px] mainPageLG:px-[15px] min-h-[7.813rem] flex items-center">
          {isDesktop ? (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={titleAnimation}
              className="text-black text-4xl leading-9 font-bold"
            >
              {t("locations.loc")}
            </motion.div>
          ) : (
            <div className="text-black text-2xl leading-9 font-bold sm:text-4xl">
              {t("locations.loc")}
            </div>
          )}
        </div>
      </section>
      <section className="py-[15px]">
        <div className="container-standard px-[30px] mainPageLG:px-[15px] text-[#ddb81e] text-xs font-bold">
          <NavLink to="/">HOME</NavLink>
          <span> / </span>
          <span>{t("locations.loc")}</span>
        </div>
      </section>
      <section className="py-[0.625rem] upMD:py-[2.813rem] bg-black">
        <div className="container-standard px-[30px] upMD:px-[50px] mainPageLG:px-[7.5rem]">
          {locations.length ? (
            <>
              {locations.map((location: LocationGroup) => {
                return (
                  <div key={location.groupName}>
                    <OneLocation locationGroup={location} />
                  </div>
                );
              })}
            </>
          ) : (
            <Loader height="300" width="1230" styles="py-10" />
          )}
        </div>
      </section>
    </main>
  );
};
