import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  FetchInfoError,
  GenericFetchType,
  LangRequest,
  ResponseType,
} from "../../types/SliceTypes/sliceType";
import axios from "axios";
import { CityInfo, CityState } from "../../types/SliceTypes/cityState";
import { MAINLINK } from "../../constants/API";

export const fetchCities = createAsyncThunk<
  ResponseType,
  LangRequest,
  { rejectValue: FetchInfoError }
>("cities/fetchCities", async (request: LangRequest, thunkApi) => {
  const response = await axios.get(`${MAINLINK}/Cities/${request.language}`);

  if (response.status !== 200) {
    return thunkApi.rejectWithValue({
      message: "Failed to fetch info.",
    });
  }

  const data: Array<CityInfo> = await response.data.data;
  return { data: data };
});

const initialState: CityState & GenericFetchType = {
  cities: [],
  status: "idle",
  error: null,
};

const citiesSlice = createSlice({
  name: "cities",

  initialState,

  reducers: {},

  extraReducers: (builder) => {
    builder.addCase(fetchCities.pending, (state) => {
      state.status = "loading";
      state.error = null;
    });

    builder.addCase(fetchCities.fulfilled, (state, { payload }) => {
      state.cities = [...payload.data];
      state.status = "idle";
    });

    builder.addCase(fetchCities.rejected, (state, { payload }) => {
      if (payload) state.error = payload.message;
      state.status = "idle";
    });
  },
});

export default citiesSlice.reducer;