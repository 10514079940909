import { useTranslation } from "react-i18next";
import { menuType, subsectionType } from "../../types/menuTypes";
import { NavLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAppSelector } from "../../store/hooks";

export const OtherServicesBlock = () => {
  const { t } = useTranslation();
  const lang = useAppSelector((state) => state.locale);
  const titles = t("titles", { returnObjects: true }) as Array<menuType>;
  const [whatWeDoTitles, setWhatWeDoTitles] = useState(
    titles.find(
      (obj: menuType) =>
        obj.title === "Our activities" ||
        obj.title === "Наша деятельность" ||
        obj.title === "Nuestras actividades" ||
        obj.title === "Unsere Aktivitäten" ||
        obj.title === "Nos activités"
    )
  );

  useEffect(() => {
    setWhatWeDoTitles(
      titles.find(
        (obj: menuType) =>
          obj.title === "Our activities" ||
          obj.title === "Наша деятельность" ||
          obj.title === "Nuestras actividades" ||
          obj.title === "Unsere Aktivitäten" ||
          obj.title === "Nos activités"
      )
    );
  }, [lang.localStorageValue]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="mb-[1.875rem] border border-[#ececec] px-5 py-[15px] shadow-additionalBlock">
      <h3 className="mb-5">{t("servicesBlock.title")}</h3>
      <div>
        <nav className="flex flex-col">
          {whatWeDoTitles?.subTittles.map((subTittle: subsectionType) => (
            <NavLink
              to={`${subTittle.route}`}
              className="inline-block text-white font-medium pb-1 mb-2.5 hover:text-[#ddb81e] duration-200"
              key={subTittle.route}
            >
              {subTittle.subTittle}
            </NavLink>
          ))}
        </nav>
      </div>
    </div>
  );
};
