import { IOurTeammate } from "./OurTeammateInterface";

export const OurTeammate = ({ name, image, styles }: IOurTeammate) => {
  return (
    <div className={`p-2 ${styles} mainPageLG:p-3`}>
      <div
        className="py-[15px] px-3 border border-[#ececec] min-h-[14.25rem] flex items-end relative shadow-additionalBlock bg-no-repeat bg-white"
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: "bottom -30px right 32px",
          backgroundSize: "auto 113%",
        }}
      >
        <div className="text-[#001433] text-base mb-2.5 block font-medium w-full bg-[#cfcfcfd1] h-8 px-2 flex items-center shadow-2xl rounded">
          {name}
        </div>
      </div>
    </div>
  );
};
