import { Route, Routes } from "react-router-dom";
import { MainPage } from "../MainPage/MainPage";
import { OurTeam } from "../OurTeam/OurTeam";
import { Locations } from "../Locations/Locations";
import { SiteMap } from "../SiteMap/SiteMap";
import { AboutUs } from "../AboutUs/AboutUs";
import { ContactUs } from "../ContactUs/ContactUs";
import { Accounting } from "../Accounting/Accounting";
import { TaxPlanning } from "../TaxPlanning/TaxPlanning";
import { Charity } from "../Charity/CharityPage";
import { WhatWeDo } from "../WhatWeDo/WhatWeDoPage";
import { FinancialServices } from "../FinancialServices/FinancialServices";
import { BusinessServices } from "../BusinessServices/BusinessServices";
import { Analytics } from "../Analytics/Analytics";
import { AuditPage } from "../Audit/AuditPage";
import { LegalAid } from "../LegalAid/LegalAid";
import { Privacy } from "../Privacy/Privacy";
import { ErrorPage } from "../ErrorPage/ErrorPage";

export const PagesRouting = () => {
  return (
    <Routes>
      <Route path="/" element={<MainPage />} />
      <Route path="/our-team" element={<OurTeam />} />
      <Route path="/locations" element={<Locations />} />
      <Route path="/site-map" element={<SiteMap />} />
      <Route path="/about-us" element={<AboutUs />} />
      <Route path="/contact-us" element={<ContactUs />} />
      <Route path="/what-we-do" element={<WhatWeDo />} />
      <Route path="/what-we-do/accounting" element={<Accounting />} />
      <Route path="/what-we-do/tax-planning" element={<TaxPlanning />} />
      <Route path="/what-we-do/financial-services" element={<FinancialServices />} />
      <Route path="/what-we-do/business-services" element={<BusinessServices />} />
      <Route path="/what-we-do/analytics" element={<Analytics />} />
      <Route path="/what-we-do/audit" element={<AuditPage />} />
      <Route path="/what-we-do/legal-aid" element={<LegalAid />} />
      <Route path="/charity" element={<Charity />} />
      <Route path="/privacy" element={<Privacy />} />
      <Route path="*" element={<ErrorPage />} />
    </Routes>
  );
};
